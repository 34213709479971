html,
body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /* background-color: rgb(241, 243, 245); */
    background-color: #F8F8F8;
}
.container{
    max-width: 400px;
  }
  
  .navbar-container {
    margin:auto;
  }
  
  .navbar-container svg {
    color: #fff;
  }
  
  a {
    text-decoration: none;
  }
  
.MuiTabs-flexContainer > button {
  color: 'orange'
}